import React from 'react';


function Error404(){
    return(
        <div className='center'>
            <h1>404</h1><br/>
            <em>The page you requested does not exist on this server</em>
        </div>
    )
}

export default Error404;